import {
  useExternalScript,
  useShowId,
  useShowInstructions,
  type ContentModule,
  type ModuleProperties,
  type GuestAuthSuccessEvent,
  type GuestAuthLogoutEvent,
  type GuestExternalAuthSuccessEventName,
} from '@backstage-components/base';
import {assertNever} from '@backstage/utils/type-helpers';
import {useSubscription} from 'observable-hooks';
import {FC, useCallback, useEffect, useMemo, useRef} from 'react';
import {ComponentDefinition, SchemaType} from './DisneyOneidAuthDefinition';

export interface IDisneyOneidAuthProps extends ModuleProperties {}

export type DisneyOneidAuthComponentDefinition = ContentModule<
  'DisneyOneidAuth',
  IDisneyOneidAuthProps & SchemaType
>;

export const DisneyOneidAuthComponent: FC<
  DisneyOneidAuthComponentDefinition
> = (definition) => {
  const {props} = definition;
  const {oneidEnvironment = 'QA'} = props;
  const oneIDRef = useRef<OneID.OneIDInstance | null>(null);
  const showId = useShowId();

  // This is the connection to instructions in the backstage system
  const {observable, broadcast} = useShowInstructions(
    ComponentDefinition.instructions,
    definition
  );

  const oneIDEnvironmentURL = useMemo(() => {
    switch (oneidEnvironment) {
      case 'QA':
        return 'https://qa.cdn.registerdisney.go.com/v4/OneID.js';
      case 'Staging':
        return 'https://stg.cdn.registerdisney.go.com/v4/OneID.js';
      case 'Production':
        return 'https://cdn.registerdisney.go.com/v4/OneID.js';
      default:
        assertNever(oneidEnvironment);
    }
  }, [oneidEnvironment]);

  const handleClose = useCallback(() => {
    broadcast({
      type: 'DisneyOneidAuth:on-close',
      meta: {},
    });
  }, [broadcast]);

  const handleLogin = useCallback(
    (data: OneID.GuestData | null) => {
      if (!data?.profile.firstName || !data?.profile.swid) {
        broadcast({
          type: 'DisneyOneidAuth:on-failure',
          meta: {error: 'Insufficient data from "login" event'},
        });
      } else {
        broadcast({
          type: 'DisneyOneidAuth:verify',
          meta: {
            email: data.profile.email ?? undefined,
            showId,
            moduleId: definition.mid,
            name: data.profile.firstName,
            swid: data.profile.swid,
          },
        });
      }
    },
    [broadcast, definition.mid, showId]
  );

  const handleLogout = useCallback(() => {
    broadcast({
      type: 'DisneyOneidAuth:on-logout',
      meta: {},
    });
  }, [broadcast]);

  useExternalScript({
    id: 'disney-oneid-component-external',
    src: oneIDEnvironmentURL,
    isParsed: () =>
      typeof window !== 'undefined' && typeof window.OneID !== 'undefined',
    onload: () => {
      if (
        typeof window === 'undefined' ||
        typeof window.OneID === 'undefined' ||
        oneIDRef.current !== null
      ) {
        return;
      }

      const u = new URL(window.location.href);
      u.pathname = '/__oneid_responder.html';
      const responderPage = u.toString();

      const oneidConfig = {
        clientId: props.disneyClientId,
        // The page that will be iframed into the current page
        responderPage: responderPage,
        cssOverride: '',
      };

      // Set a ref to the OneID object
      try {
        oneIDRef.current = window.OneID.get(oneidConfig);
      } catch (reason) {
        if (
          reason instanceof Error &&
          reason.message === 'Configuration options have already been set'
        ) {
          oneIDRef.current = window.OneID.get();
        } else {
          throw reason;
        }
      }
      const instance = oneIDRef.current;

      if (instance === null) {
        return;
      }
      if (!window.__isOneIDInitialized) {
        window.__isOneIDInitialized = true;
        instance
          .init()
          .then(function (data) {
            broadcast({
              type: 'DisneyOneidAuth:on-init',
              meta: {
                isLoggedIn: data.loggedIn.toString(),
              },
            });
            // Get the guest data. The response to `init` does not inlcude the
            // guest data so this additional call is necessary to get details
            // about someone already authenticated
            if (data.loggedIn) {
              // If `#init` reports OneID as already `loggedIn` presumably they
              // had already authenticated on this site (as long as the domains
              // are unique). This `:verify` instruction may not be necessary.
              instance
                .getGuest()
                .then((data) => {
                  broadcast({
                    type: 'DisneyOneidAuth:verify',
                    meta: {
                      email: data.profile.email ?? undefined,
                      showId,
                      moduleId: definition.mid,
                      name: data.profile.firstName,
                      swid: data.profile.swid,
                      shouldReauth: false,
                    },
                  });
                })
                .catch((error) => {
                  console.error(
                    'There was an error getting the guest data',
                    error
                  );
                  broadcast({
                    type: 'DisneyOneidAuth:on-init-error',
                    meta: {reason: `${error}`, step: 'getGuestPostInit'},
                  });
                });
            }
          })
          .catch(function (error) {
            console.error('There was an error initializing OneID', error);
            broadcast({
              type: 'DisneyOneidAuth:on-init-error',
              meta: {reason: `${error}`, step: 'init'},
            });
          });
      } else {
        // If OneID is already initialized get the cached guest information.
        // This is mostly likely to happen on a browser "Back" navigation
        instance
          .getLoggedInStatus()
          .then((isLoggedIn) => {
            if (isLoggedIn) {
              return instance.getGuest();
            } else {
              return null;
            }
          })
          .then((data) => {
            if (data === null) {
              // this indicates there is not a logged in guest upon return to
              // the page. Don't try and verify.
              return;
            }
            broadcast({
              type: 'DisneyOneidAuth:verify',
              meta: {
                email: data.profile.email ?? undefined,
                showId,
                moduleId: definition.mid,
                name: data.profile.firstName,
                swid: data.profile.swid,
                shouldReauth: false,
              },
            });
          })
          .catch((error) => {
            console.error('There was an error getting the guest data', error);
            broadcast({
              type: 'DisneyOneidAuth:on-init-error',
              meta: {reason: `${error}`, step: 'getGuest'},
            });
          });
      }

      // Need the correct types here
      instance.on('login', handleLogin);
      instance.on('close', handleClose);
      instance.on('logout', handleLogout);
    },
    onunload: () => {
      const instance = oneIDRef.current;
      if (instance === null) {
        return;
      }
      instance.off('close', handleClose);
      instance.off('login', handleLogin);
      instance.off('logout', handleLogout);
    },
  });

  useSubscription(observable, (instruction) => {
    // If the Intercom function isn't available yet we can't do much; bail out
    const oneId = oneIDRef.current;
    if (
      typeof window === 'undefined' ||
      typeof window.OneID === 'undefined' ||
      oneId === null
    ) {
      console.debug({instruction, msg: 'OneID not initialized'});
      return;
    } else if (instruction.type === 'DisneyOneidAuth:launch-login') {
      oneId
        .launchLogin()
        .then(() => {
          broadcast({
            type: 'DisneyOneidAuth:on-launch-login',
            meta: {},
          });
        })
        .catch((error) => {
          console.error('Error launching login', error);
          broadcast({
            type: 'DisneyOneidAuth:on-error',
            meta: {reason: `${error}`, step: 'launchLogin'},
          });
        });
    } else if (instruction.type === 'DisneyOneidAuth:launch-register') {
      oneId
        .launchRegistration()
        .then(() => {
          broadcast({
            type: 'DisneyOneidAuth:on-launch-register',
            meta: {},
          });
        })
        .catch((error) => {
          console.error('Error launching registration', error);
          broadcast({
            type: 'DisneyOneidAuth:on-error',
            meta: {reason: `${error}`, step: 'launchRegistration'},
          });
        });
    } else if (instruction.type === 'DisneyOneidAuth:success') {
      const eventName: GuestExternalAuthSuccessEventName =
        'GuestExternalAuth:success';
      const e: GuestAuthSuccessEvent = new CustomEvent(eventName, {
        detail: {attendee: instruction.meta.attendee, showId},
      });
      document.body.dispatchEvent(e);
    } else if (instruction.type === 'DisneyOneidAuth:verify-skipped') {
      const {attendee} = instruction.meta;
      broadcast({
        type: 'DisneyOneidAuth:on-success',
        meta: {
          attendeeEmail: attendee.email,
          attendeeId: attendee.id,
          attendeeName: attendee.name,
          attendeeTags: attendee.tags.join(','),
          showId,
        },
      });
    } else if (instruction.type === 'DisneyOneidAuth:failure') {
      oneId.logout().finally(() =>
        broadcast({
          type: 'DisneyOneidAuth:on-failure',
          meta: {
            error:
              instruction.meta.reason ??
              'Failed to save authentication details',
          },
        })
      );
    }
  });

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined = undefined;
    const onAuthSuccess = (e: GuestAuthSuccessEvent): void => {
      const {detail} = e;
      timeoutId = setTimeout(() => {
        broadcast({
          type: 'DisneyOneidAuth:on-success',
          meta: {
            showId: detail.showId,
            attendeeId: detail.attendee.id,
            attendeeEmail: detail.attendee.email,
            attendeeName: detail.attendee.name,
            attendeeTags: detail.attendee.tags.join(','),
          },
        });
      }, 50);
    };
    document.body.addEventListener('GuestExternalAuth:success', onAuthSuccess);
    return () => {
      document.body.removeEventListener(
        'GuestExternalAuth:success',
        onAuthSuccess
      );
      clearTimeout(timeoutId);
    };
  }, [broadcast]);

  // Listen for `GuestAuth:logout` events and perform the `#logout` method on
  // the OneID instance. The `:on-logout` instruction will be triggered by the
  // event listener on the OneID instance.
  useEffect(() => {
    const listener = (_: GuestAuthLogoutEvent): void => {
      const oneId = window.OneID?.get();
      oneId
        ?.getLoggedInStatus()
        .then((isLoggedIn) => (isLoggedIn ? oneId?.logout() : void 0));
    };
    if (typeof window.__isOneIDInitialized === 'undefined') {
      document.body.addEventListener('GuestAuth:logout', listener);
    }
    // Don't remove the `GuestAuth:logout` listener because if this fires
    // without the module on the page the `#logout` should still occur
  }, []);

  return null;
};
